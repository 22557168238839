import { template as template_5a5a5a34f1494c98b00605df95920657 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_5a5a5a34f1494c98b00605df95920657(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
