import { template as template_62382f1aea154bb5a6b923011af0f57a } from "@ember/template-compiler";
const FKControlMenuContainer = template_62382f1aea154bb5a6b923011af0f57a(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
